/*Empty CSS file for your own CSS*/
.notMoving {
    color: lightblue;
}

#ajaxSpinnerContainer {
    height: 11px;
}

#ajaxSpinnerImage {
    display: none;
}

.hero-widget {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.hero-widget .icon {
    display: block;
    font-size: 96px;
    line-height: 96px;
    margin-bottom: 10px;
    text-align: center;
}

.hero-widget var {
    display: block;
    height: 64px;
    font-size: 64px;
    line-height: 64px;
    font-style: normal;
}

.hero-widget label {
    font-size: 17px;
}

.hero-widget .options {
    margin-top: 10px;
}

.expense {
    background-color: #f39c12;
}

.income {
    background-color: #00a65a;
}